<template>
	<li :class="'depth-' + depth">
		<div class="comment">
			<img
				alt=""
				:src="getAuthorAvatarUrl()"
				class="avatar avatar-48 photo rounded-[8px] md:rounded-[12px] h-[36px] w-[36px] lg:h-[60px] lg:w-[60px]"
				loading="lazy"
				decoding="async"
				width="48"
				height="48"
			/>

			<div>
				<div
					class="comment-header flex lg:items-center flex-col lg:flex-row flex-wrap lg:gap-[8px]"
					:class="{
						'mb-[12px]': Number(comment.rating) < 1
					}"
				>
					<span class="text-base text-slate-900">
						<b>{{ comment.author }}</b>
					</span>

					<span
						class="hidden md:block h-[4px] w-[4px] rounded-[8px] bg-slate-900"
					></span>
					<time
						class="comment-time text-slate-600"
						:datetime="comment.dateGmt"
					>
						<span class="comment-time-link">
							{{ formatCommentDate(comment.date) }}
						</span>
					</time>
				</div>
				<div
					v-if="depth === 1 && Number(comment.rating) > 0"
					class="comment-content"
					v-html="generateRatingStarsHtml(comment.rating)"
				></div>
				<div
					class="comment-content"
					v-html="replaceContentUrl"
				></div>
				<button
					class="comment-reply-link"
					:aria-label="'Reply to ' + comment.author"
					@click.prevent="$emit('replyTo', comment.id)"
				>
					Reply
				</button>
			</div>
		</div>

		<ul
			class="children"
			v-if="comment.children.length > 0"
		>
			<comment
				v-for="item in comment.children"
				:key="item.id"
				:comment="item"
				:depth="depth + 1"
				:replyToComment="replyToComment"
				@replyTo="(commentId) => $emit('replyTo', commentId)"
			></comment>
		</ul>

		<comment-form
			:id="`replying-comment-${comment.id}`"
			v-if="replyToComment && comment.id === replyToComment.id"
			:postId="comment.postId"
			:replyToComment="replyToComment"
			@cancel="$emit('replyTo', null)"
		></comment-form>
	</li>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import md5 from "md5";
import CommentForm from "~/components/comment-form.vue";

export default defineComponent({
	emits: ["replyTo"],
	props: ["comment", "depth", "replyToComment"],
	components: [CommentForm],
	computed: {
		replaceContentUrl() {
			if (["contentstt.", "content."]) {
				const replacedContent = this.comment.content.replace(
					/https:\/\/contentstt\.wpenginepowered\.com\//g,
					"https://stockstotrade.com/"
				);

				const clickableContent = replacedContent.replace(
					/(https?:\/\/[^\s]+)/g,
					'<a href="$1" target="_blank" rel="noopener noreferrer" class="clickable">$1</a>'
				);

				return clickableContent;
			}

			return this.comment.content;
		}
	},
	methods: {
		md5: md5,
		formatCommentDate: formatCommentDate,
		generateRatingStarsHtml: (rating = 0) => {
			let starsHtml = "";
			rating = parseInt(rating);

			for (let i = 1; i <= 5; i++) {
				starsHtml +=
					i <= rating
						? '<span class="str-ic""></span>'
						: '<span class="df-clr"></span>';
			}

			return `<div class="saswp-rvw-str">${starsHtml}</div>`;
		},
		getAuthorAvatarUrl() {
			if (
				this.comment.authorUrl &&
				this.comment.authorUrl.startsWith("https://secure.gravatar.com")
			) {
				return this.comment.authorUrl;
			} else if (this.comment.authorEmail) {
				const gravatarHash = md5(this.comment.authorEmail);

				return `https://secure.gravatar.com/avatar/${gravatarHash}?s=48&d=mm&r=g`;
			} else {
				return `https://secure.gravatar.com/avatar/default?d=mm`;
			}
		}
	}
});
</script>

<style lang="scss">
.comment {
	border-radius: 12px;
	border: 1px solid $slate-100;
	background: $slate-25;
	padding: 12px 12px 20px;
	display: flex;
	gap: 12px;
	@include breakpoint(md) {
		gap: 16px;
		padding: 12px 16px 20px;
	}
}

.comment-reply-link {
	@include dmSans;
	font-size: 16px;
	font-weight: 600;
	color: $blue-300;
	&:hover {
		color: $blue-500;
	}
}

.form-error {
	color: $red-600;
	margin-top: 8px;
	padding: 0 16px;
}

.comment-content {
	word-break: break-word;
	color: #6b7280;
	margin-bottom: 1em;

	.clickable {
		color: $blue-500;

		&:hover {
			text-decoration: underline;
		}
	}
}
</style>
