<template>
	<div class="title-container">
		<h2
			class="title"
			:style="{ fontSize: applyCustomStyle ? '22px' : '24px' }"
		>
			Related Posts
		</h2>
		<NuxtLink
			class="blogs-and-insights"
			:style="{ fontSize: applyCustomStyle ? '16px' : '18px' }"
			to="/blog"
		>
			All Blogs & Insights
		</NuxtLink>
	</div>
	<div class="post-thumbnails-container">
		<div class="post-item-container">
			<AdButlerItem
				:ad-id="741015"
				v-if="showAds && show_adbutler"
			/>

			<NuxtLink
				v-for="(post) in shuffleArray(posts).slice(0, 3)"
				:key="post.slug"
				class="post-item"
				:to="`/${post.slug}/`"
			>
				<RelatedPostImage
					:sourceUrl="
						post.featuredImage
							? post.featuredImage.node.sourceUrl
							: '/images/daily-acelerator.webp'
					"
					:slug="post.slug"
					class="post-thumbnail"
				/>
				<div class="post-info-container">
					<span class="post-tag">
						<NuxtLink :to="`/category/${post.categories.nodes[0].slug}/`">
							{{ post.categories.nodes[0].name }}
						</NuxtLink>
					</span>
					<span class="post-title">{{ post.title }}</span>
					<div class="post-info-date-and-time">
						<span class="post-date">{{ dateFormat(post.dateGmt) }}</span>
						<span class="post-time">
							{{ calculateReadingTime(post.content) }} min read
						</span>
					</div>
				</div>
			</NuxtLink>
		</div>
	</div>
</template>
<script setup>
import { AdButlerItem } from "@stockstotrade_com/stt-ts-shared-lib";

const props = defineProps(["posts", "applyCustomStyle"]);
const showAds = ref(false);
const posts = ref(props.posts);
const { show_adbutler } = useRuntimeConfig().public;
onMounted(() => {
	if (process.client) {
		showAds.value = window.adsAreWithUs;
	}
});

watch(
	() => props.posts,
	(newPosts) => {
		posts.value = newPosts;
	}
);
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.title-container {
	padding-bottom: 24px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	@include breakpoint(lg) {
		padding-bottom: 36px;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}

.title {
	color: #0d0d12;
	font-family: Open Sauce Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 28.8px */
	letter-spacing: -2px;
}

.blogs-and-insights {
	color: #4383ff;
	text-align: center;
	font-family: DM Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 26px; /* 144.444% */

	&::after {
		content: "";
		display: inline-block;
		width: 28px;
		height: 28px;
		background-color: #4a8fff;
		background-image: url("/images/arrow-right.svg");
		background-repeat: no-repeat;
		background-size: 18px;
		background-position: center;
		border-radius: 50%;
		margin-left: 8px;
		vertical-align: middle;
	}
}

.post-thumbnails-container {
	display: flex;
	align-items: center;
}

.post-item {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 24px;

	.post-item:last-child {
		margin-bottom: 0;
	}

	@include breakpoint(lg) {
	}
}

.post-info-container {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.post-thumbnail-container {
	margin-right: 16px;
}

.post-thumbnail {
	:deep(img) {
		border-radius: 8px;
		max-width: 138px;
		max-height: 100px;

		@include breakpoint(md) {
			width: 138px;
			height: 100px;
		}
	}
}

.post-tag {
	color: #4383ff;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 24px */
	display: flex;
}

.post-title {
	color: #0d0d12;
	font-family: DM Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 133.333% */
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	-webkit-line-clamp: 2;
}

.post-info-date-and-time {
	span {
		overflow: hidden;
		color: #666d80;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-family: DM Sans;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 1;
		margin-bottom: 0;
	}

	.post-date {
		&::after {
			content: "|";
			margin: 0 4px;
			color: #666d80;
			font-family: DM Sans;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 1;
		}
	}
	.post-time {
		padding-left: 0;
	}
}
</style>
