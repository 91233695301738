<template>
	<div
		id="respond"
		:class="(`comment-respond mt-[32px]`, { 'reply-comment': replyToComment })"
	>
		<h3
			v-if="!replyToComment"
			id="reply-title"
			class="comment-reply-title mb-[16px]"
		>
			Leave your opinion
		</h3>
		<h3
			v-if="replyToComment"
			id="reply-title"
			class="comment-reply-title mt-[24px] mb-[16px]"
		>
			Reply to {{ replyToComment.author }}
			<span class="comment-reply">
				<a
					href="#"
					@click.prevent="$emit('cancel')"
					class="comment-reply-link"
				>
					(cancel reply)
				</a>
			</span>
		</h3>

		<form
			@submit.prevent="submitComment"
			id="commentform"
			:class="`comment-form mb-[32px] lg:mb-[64px]`"
		>
			<client-only v-if="!replyToComment">
				<div class="mb-[32px]">
					<p class="text-lg text-slate-900">Leave a rating</p>
					<star-rating
						@update:rating="setRating"
						:rating="rating"
						:show-rating="false"
						:star-size="35"
						inactive-color="#635E63"
					></star-rating>
				</div>
			</client-only>
			<div
				class="input-group input-comment mb-[16px]"
				:class="commentError ? 'error' : ''"
			>
				<textarea
					class="form-control"
					id="comment"
					name="comment"
					ref="comment"
					cols="45"
					rows="8"
					aria-required="true"
					placeholder="Comment"
					@input="commentError = null"
				></textarea>
				<span
					v-if="commentError"
					class="form-error"
				>
					{{ commentError }}
				</span>
			</div>

			<div class="flex flex-col md:flex-row gap-[16px]">
				<div
					class="input-group comment-form-author"
					:class="authorError ? 'error' : ''"
				>
					<input
						type="text"
						class="form-control"
						placeholder="Name"
						id="author"
						name="author"
						ref="author"
						value=""
						aria-required="true"
						@input="authorError = null"
					/>
					<span
						v-if="authorError"
						class="form-error"
					>
						{{ authorError }}
					</span>
				</div>

				<div
					class="input-group comment-form-email"
					:class="emailError ? 'error' : ''"
				>
					<input
						type="text"
						class="form-control"
						placeholder="Email"
						id="email"
						name="email"
						ref="email"
						value=""
						aria-required="true"
						@input="emailError = null"
					/>
					<span
						v-if="emailError"
						class="form-error"
					>
						{{ emailError }}
					</span>
				</div>

				<button
					id="submit"
					class="submit"
				>
					Post comment
				</button>
			</div>
		</form>

		<div
			class="comment-sent-success mt-[32px] mb-[32px]"
			ref="successMessage"
		>
			<img
				src="/images/success-comment.svg"
				class="max-w-[200px] mx-auto mb-[16px]"
				alt=""
			/>
			<h3 id="reply-title">Thank you for leaving a comment!</h3>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
	emits: ["cancel"],
	data() {
		return {
			authorError: null,
			emailError: null,
			commentError: null,
			rating: null,
			sent: false
		};
	},
	props: ["postId", "replyToComment"],
	computed: {
		showMsg() {
			return Boolean(this.sent);
		}
	},
	methods: {
		setRating(rating) {
			this.rating = rating;
		},
		submitComment(e) {
			const comment = this.$refs.comment.value;
			const author = this.$refs.author.value;
			const email = this.$refs.email.value;

			if (!comment || !author || !email) {
				if (!comment) {
					this.commentError = "Invalid message.";
				}

				if (!email) {
					this.emailError = "Invalid email.";
				}

				if (!author) {
					this.authorError = "Invalid name.";
				}

				return;
			}

			this.commentError = null;
			this.emailError = null;
			this.authorError = null;

			this.postComment({ comment, author, email, post_id: this.postId });
		},
		async postComment(props) {
			const formData = new FormData();
			formData.append("comment_post_ID", props.post_id);
			formData.append("comment", props.comment);
			formData.append("author", props.author);
			formData.append("email", props.email);
			formData.append("review_rating", this.rating);

			if (this.replyToComment) {
				formData.append("comment_parent", this.replyToComment.id);
			}

			this.showSuccessMessage();
			
			const { data, status } = await axios.post(
				this.$config.public["hostname"] + "wp-comments-post.php",
				formData
	
			);

			// Only shows the message if we have a successful response, redirection, or resource creation.
			/*if (status == 200 || status == 201) {
				this.showSuccessMessage();
			}*/
		},
		showSuccessMessage(){
			const msg = this.replyToComment
					? document.querySelector(".reply-comment .comment-sent-success")
					: this.$refs.successMessage;

			msg.classList.add("show");

			setTimeout(() => {
				msg.classList.add("dissappear");

				setTimeout(() => {
					msg.classList.remove("dissappear");
					msg.classList.remove("show");

					if (this.replyToComment) {
						this.$emit("cancel");
					}
				}, 1010);
			}, 5000);
		}
	}
});
</script>

<style lang="scss" scoped>
@keyframes fadeToBlack {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.dissappear {
	animation: fadeToBlack 2s ease-in-out forwards;
}
.comment-reply-title {
	@include openSauceSans500;
	color: $slate-900;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 28.8px */
}

.form-control {
	border-radius: 12px;
	border: 1px solid $slate-100;
	background: $slate-25;
	padding: 12px 16px;
	line-height: 1;
	font-size: 16px;
	width: 100%;
	color: $slate-900;
	text-align: left;
}

button.submit {
	background: linear-gradient(110.32deg, #091d56 8.05%, #331290 77.58%);
	color: white;
	width: 100%;
	border-radius: 12px;
	transition: all 250ms ease-in-out;
	min-height: 46px;
	@include breakpoint(md) {
		height: 46px;
		max-width: 226px;
	}
	&:hover {
		background: linear-gradient(110.32deg, #331290 8.05%, #091d56 77.58%);
		color: rgba($color: $white, $alpha: 0.75);
	}
}

.comment-sent-success {
	display: none;
	padding: 32px;
	border: 1px solid rgba($green-900, 0.25);
	background-color: $slate-25;
	text-align: center;
	color: $slate-900;
	font-size: 32px;
	border-radius: 12px;
	box-shadow: 0 0 16px rgba($green-300, 0.15);
}
.comment-sent-success.show {
	display: block;
}
</style>
