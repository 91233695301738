<template>
	<comment-form
		id="comment-form"
		:postId="postId"
	></comment-form>

	<div
		v-if="comments.length > 0"
		class="entry-comments"
		id="comments"
	>
		<h3
			class="text-slate-900 text-[20px] lg:text-[24px] font-semibold mb-[24px]"
			id="commens-section-heading"
		></h3>
		<ol class="comment-list">
			<comment
				v-for="(comment, index) in commentsHierarchically"
				:key="comment.id"
				:comment="comment"
				:depth="1"
				:replyToComment="replyToComment"
				@replyTo="setReplyTo"
			></comment>
		</ol>
	</div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

function unflatten(items) {
	const tree = [];
	const mappedArr = {};

	items.forEach((item) => {
		var id = item.id;
		mappedArr[id] = item;
		mappedArr[id].children = [];
	});

	for (var id in mappedArr) {
		let mappedElem = mappedArr[id];

		if (parseInt(mappedElem.parentId) > 0) {
			var parentId = mappedElem.parentId;
			mappedArr[parentId].children.push(mappedElem);
		} else {
			tree.push(mappedElem);
		}
	}

	return tree;
}

export default defineComponent({
	props: ["comments", "postId"],
	data: () => {
		return {
			replyToComment: null
		};
	},
	mounted() {
		if (process.client) {
		}
	},
	computed: {
		commentsHierarchically() {
			return unflatten(this.comments).reverse();
		}
	},
	watch: {
		comments(newComm, oldComm) {
			if (process.client && newComm.length > 0) {
				setTimeout(() => {
					const el = document.querySelector("#commens-section-heading");
					el.innerHTML = `Comments (<span class="text-blue-300">${newComm.length}</span>)`;
				}, 20);
			}
		}
	},
	methods: {
		setReplyTo(commentId) {
			this.replyToComment = this.comments.filter(
				(comment) => comment.id === commentId
			)[0];
		}
	}
});
</script>

<style lang="scss">
.comment-list {
	display: flex;
	flex-direction: column;
	gap: 16px;
	@include breakpoint(lg) {
		gap: 32px;
	}
}

.children {
	padding-left: 24px;
	margin-top: 24px;

	@include breakpoint(lg) {
		padding-left: 36px;
		margin-top: 32px;
	}
}
</style>
