<template>
	<div class="flex flex-wrap gap-[16px]">
		<div>
			<h4 class="ratings-subtitle">What our users think</h4>
			<div class="saswp-average-rating">
				<b class="text-slate-900">{{ rating }}</b>
				Based on {{ ratingsCount }}
			</div>
		</div>
		<div v-html="generateRatingStarsHtml(rating)"></div>
	</div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
	props: ["comments"],
	computed: {
		flattenedArray() {
			const flattenArray = (arr) => {
				return arr.reduce((acc, obj) => {
					acc.push(obj);
					if (obj.children && obj.children.length > 0) {
						acc = acc.concat(flattenArray(obj.children));
						delete obj.children; // Optional: Remove the 'children' property after flattening
					}
					return acc;
				}, []);
			};
			const flattened = flattenArray(this.comments);

			if (flattened.length > 9) return flattened;

			return [];
		},
		rating() {
			let sum = 0;

			if (this.flattenedArray) {
				this.flattenedArray.forEach((comment) => {
					const rating = parseInt(comment.rating);

					if (rating > 0 && typeof rating === "number") sum += rating;
				});

				return Number.parseFloat(sum / this.ratingsCount).toFixed(1);
			}

			return 0;
		},
		ratingsCount() {
			const count = this.flattenedArray.filter((item) => item.rating);

			return count.length;
		}
	},
	methods: {
		generateRatingStarsHtml: (rating = 0) => {
			let starsHtml = "";
			rating = parseInt(rating);

			for (let i = 1; i <= 5; i++) {
				starsHtml +=
					i <= rating
						? '<span class="str-ic""></span>'
						: '<span class="df-clr"></span>';
			}

			return `<div class="saswp-rvw-str">${starsHtml}</div>`;
		}
	}
});
</script>

<style lang="scss" scoped>
.ratings-subtitle {
	@include openSauceSans500;
	color: $slate-900;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 24px */
}

.saswp-average-rating {
	color: $slate-500;
}
</style>
