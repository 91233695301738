<template>
	<div
		class="article-contributors"
		:class="classes"
	>
		<span class="flex items-center gap-[8px]">
			<div class="contributor-profile-images inline-flex flex-wrap gap-2 mr-[8px]">
				<span class="flex contributor-profile-avatar">
					<img
						class="rounded-[32px]"
						height="32"
						width="32"
						:src="filterAvatar"
						:alt="author.name + 'Avatar'"
					/>
				</span>
				<span
					v-if="reviewer && reviewer.avatar"
					class="flex contributor-profile-avatar"
				>
					<img
						class="rounded-[32px]"
						height="32"
						width="32"
						:src="reviewer.avatar"
						:alt="reviewer.name + 'Avatar'"
					/>
				</span>
				<span
					v-if="factChecker && factChecker.avatar"
					class="flex contributor-profile-avatar"
				>
					<img
						class="rounded-[32px]"
						height="32"
						width="32"
						:src="factChecker.avatar"
						:alt="factChecker.name + 'Avatar'"
					/>
				</span>
			</div>

			<span class="text-base font-normal">
				Written by
				<NuxtLink
					:to="generateBioPageLink(author.name)"
					class="author-link"
				>
					{{ author.name === "Tim Bohen" ? "Tim Bohen" : author.name }}
				</NuxtLink>
			</span>
		</span>
		<span
			v-if="reviewer && reviewer.name"
			class="md:border-l-2 border-slate-300 block md:inline-block w-full md:w-auto text-base md:leading-none pl-[48px] md:pl-[8px] font-normal"
		>
			Reviewed by
			<NuxtLink
				:to="generateBioPageLink(reviewer.name)"
				class="author-link"
			>
				{{ reviewer.name }}
			</NuxtLink>
		</span>
		<span
			v-if="factChecker && factChecker.name"
			class="md:border-l-2 border-slate-300 block md:inline-block w-full md:w-auto text-base md:leading-none pl-[48px] md:pl-[8px] font-normal"
		>
			Fact-checked by
			<NuxtLink
				:to="generateBioPageLink(factChecker.name)"
				class="author-link"
			>
				{{ factChecker.name }}
			</NuxtLink>
		</span>
	</div>
</template>

<script setup lang="ts">
type Authorship = {
	class?: string;
	author: {
		avatar: string;
		name: string;
	};
	reviewer: {
		avatar: string;
		name: string;
	};
	factChecker: {
		avatar: string;
		name: string;
	};
};

const props = defineProps<Authorship>();

const classes = computed(() => props.class);

const generateBioPageLink = (name: string) => {
	let url = name.includes("-")
		? name.replace("-", " ").split(" ").join("-")
		: name.split(" ").join("-");

	url = url === "Tim-Bohen" ? "tim-bohen" : url;

	const pages = [
		"bohen-bot",
		"ed-weinberg",
		"friedrich-odermann",
		"jordan-s",
		"stephanie-thompson",
		"tim-bohen",
		"tim-bot",
		"tim-sykes",
		"timothysykes"
	];

	if (!pages.includes(url.toLocaleLowerCase())) {
		url = "tim-bohen";
	}

	return `/authors/${url.toLowerCase()}/`;
};

const filterAvatar = computed(() => {
	if (props.author.name === "Tim Bohen") {
		return props.author.avatar;
	} else if (props.author.name === "Tim-bot") {
		return "/images/authorsPics/timbot.webp";
	}

	return props.author.avatar;
});
</script>

<style lang="scss" scoped>
.article-contributors {
	display: flex;
	gap: 8px;
	color: $slate-500;
	align-items: center;
	flex-wrap: wrap;

	@include breakpoint(md) {
		flex-wrap: nowrap;
	}
}

.contributor-profile-avatar {
	height: 32px;
	width: 32px;
	border-radius: 32px;
	overflow: hidden;
}
</style>
